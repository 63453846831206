// src/components/Strains/Strains.js
import React from 'react';
import Card from '../Card/Card';
import './Strains.css';

// Import images from the src directory -  this should be done from a database in a long run
import F0008_1 from '../../assets/strains/BC-F0008_1.jpg';
import F0008_3 from '../../assets/strains/BC-F0008_3.jpg';
import F0034_1 from '../../assets/strains/BC-F0034 (1).jpg';
import F0034_2 from '../../assets/strains/BC-F0034 (2).jpg';
import F0041_1 from '../../assets/strains/BC-F0041 (2).jpg';
import F0041_2 from '../../assets/strains/BC-F0041 (3).jpg';

const Strains = () => {

// Temporary condition to show contact info instead of existing content
const showContactInfo = true;

if (showContactInfo) {
  return (
    <div className="strains-page">
      <div className="temporary-message">
        <h2>Contact us to learn more about our biobank and our services</h2>
        <p>If you have any enquiries about our strains, please contact us at:</p>
        <p>Email: <a href="mailto:james@bravelycultured.com">james@bravelycultured.com</a></p>
      </div>
    </div>
  );
}

    // the code below is a quick and dirty way to create a demo. 
    // To do it properly I will need to write a code that will take the displayd info from the database 
  const strainsData = [
    {
      title: 'BC-F0008',
      images: [F0008_1, F0008_3],
      species: 'Colletotrichum dematium',
      identity: '92.86%',
      completeness: '90%',
      observations: 'Isolated from grass/seaweed. Flat matte colonies. Sporulated on YPD and PDA media. Brown spores. Recovered well.',
      articles: 'N/A'
    },
    {
      title: 'BC-F0034',
      images: [F0034_1, F0034_2],
      species: 'Penicillium camemberti ',
      identity: '96.54%',
      completeness: '100%',
      observations: 'Isolated from dried and decaying wooden sticks and mixed root biomass. Isolated on YPD. Circular colonies with radial furrows, light green center, and dark green periphery. Light orange reverse pigmentation. Sporulates on YPD and PDA.',
      articles: 'Relevant articles about Strain 2.'
    },

    {
        title: 'BC-F0041',
        images: [F0041_1, F0041_2],
        species: 'Cladosporium sp.',
        identity: '100%',
        completeness: '100%',
        observations: 'Isolated from seawater on sucrose. Circular colonies with slightly raised centre. Dense mustard brown spores.',
        articles: 'Relevant articles about Strain 2.'
      },
    // Add more data as needed
  ];

  return (
    <div className="strains-page">
      {strainsData.map((strain, index) => (
        <Card 
          key={index}
          title={strain.title}
          images={strain.images}
          species={strain.species}
          identity={strain.identity}
          completeness={strain.completeness}
          observations={strain.observations}
          articles={strain.articles}
        />
      ))}
    </div>
  );
};

export default Strains;
