// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './global.css';
import Navbar from './components/Navbar/Navbar';
import Strains from './components/Strains/Strains'; // Import the new Strains component
import Banner from './components/Banner/Banner';
import Footer from './components/Footer/Footer'; // Import the Footer component

const App = () => {
  return (
    <Router>
      <div className="App">
        <Navbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/strains" element={<Strains />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

const HomePage = () => {
  return (
    <div>
      <Banner />
    </div>
  );
};

export default App;


