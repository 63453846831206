// src/components/Card/Card.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Card.css';

const Card = ({ title, images, species, identity, completeness, observations, articles }) => {
    const observationList = Array.isArray(observations) ? observations : observations.split('.').filter(obs => obs.trim() !== '').map(obs => obs.trim() + '.');
    return (
      <div className="card">
            <div className="card-image">
                <Carousel showThumbs={false}>
                    {images.map((image, index) => (
                    <div key={index}>
                        <img src={image} alt={`${title} ${index + 1}`} />
                    </div>
                    ))}
                </Carousel>
            </div>
            <div className="card-content">
                <h2 className="card-title">{title}</h2>
                <div className="card-info">
                        <div className="card-info-row">
                            <p>
                                <span className="species-name">{species}</span>
                                <br />
                                <span className="relative-text">*Closest relative</span>
                            </p>
                            <p><strong>% Identity:</strong> {identity}</p>
                            <p><strong>Completeness:</strong> {completeness}</p>
                        </div>
                        <div className="card-info-row">
                            <div>
                                <strong>Observations:</strong>
                                <ul className="observation-list">
                                    {observationList.map((observation, index) => (
                                    <li key={index}>{observation}</li>
                                    ))}
                                </ul>
                            </div>
                            {/* <div>
                                <strong>Articles:</strong> {articles}
                            </div> */}
                        </div>
                </div>
            </div>
        </div>
    );
  };
  
  export default Card;

