import React from 'react';
import './Banner.css'; // Import the CSS file for styles
import bannerImage from '../../assets/banner.png';



const Banner = ({ text }) => {
  return (
    <div className="banner">
        <img src={bannerImage} alt="Banner" />
        <h1>Microbial biobank and <br /> Strain improvement</h1>
        <h2>We work with marine fungi, bacteria and yeast</h2>
        {/* <a href="https://www.linkedin.com/company/bravelycultured" className="linkedin-button">Find us on LinkedIn</a> */}
        <link href="https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500;600;700&display=swap" rel="stylesheet"></link>
    </div>
  );
};

export default Banner;